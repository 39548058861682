import "../CSS/Common.css";
import React, { useState } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import Efficacy_of_vonoprazan from "./pdfs/Efficacy_of_vonoprazan.pdf";
import Evidance_based_guideline_2021 from "./pdfs/Evidance_based_guideline_2021.pdf";
import Maintenance_of_Erosive_esophagitis from "./pdfs/Maintenance_of_Erosive_esophagitis_(Vonoprazan_VS_Lansoprazole).pdf";
import P_CAB_GERD from "./pdfs/P-CAB_&_GERD.pdf";
import pharmacodynamics_and_pharmacokinetics from "./pdfs/pharmacodynamics_and_pharmacokinetics_of_Vonoprazan vs Lansoprazole.pdf";
import Phase_3_clinical_trial_vonoprazan from "./pdfs/Phase_3_clinical_trial_vonoprazan.pdf";
import Randomised_trial_of_acid_inhibition_by_vonoprazan_10_20_mg_once_daily_vs_Rabeprazole from "./pdfs/Randomised_trial_of_acid_inhibition_by_vonoprazan_10_20_mg_once_daily_vs_Rabeprazole.pdf";
import Vonoprazan_for_GERD_clinical_study from "./pdfs/Vonoprazan_for_GERD_clinical_study.pdf";
import Vonoprazan_for_GERD from "./pdfs/Vonoprazan_for_GERD.pdf";
import Vonoprazan_for_H_pylori from "./pdfs/Vonoprazan_for_H.pylori.pdf";
import Vonoprazan_for_NERD from "./pdfs/Vonoprazan_for_NERD_(phase_3_clinical_study).pdf";
import Vonoprazan_fumarate_for_acid_related_disorders from "./pdfs/Vonoprazan_fumarate_for_acid_related_disorders.pdf";
import Vonoprazan_prevents_NSAIDs_induced_ulcers from "./pdfs/Vonoprazan_prevents_NSAIDs_induced_ulcers.pdf";
import Vonoprazan_prevents_recurrent_ulcers from "./pdfs/Vonoprazan_prevents_recurrent_ulcers_(Phase_3_clinical_study).pdf";
import VonoprazanGuideline1 from "./pdfs/VonoprazanGuideline1.pdf";

// import temp_video from "https://youtu.be/oc_o5DZM3qc?si=O8xEUhbz05ReMjE7";
import article_image from "./image.png";
import yt_img from "./yt_image.png";
import pdf_img from "./pdf_img.png";
import yt_video_icon from "./round_yt_img.png";

function DisplayVideo(props) {
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [showPdfModal, setShowPdfModal] = useState(false);
  const [expandedDescriptionIndices, setExpandedDescriptionIndices] = useState(
    []
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryVideo, setSearchQueryVideo] = useState("");
  const [playingVideoIndex, setPlayingVideoIndex] = useState(null); // Track playing video index

  const [openArticleTab, setOpenArticleTab] = useState(false);
  const [openVideoTab, setOpenVideoTab] = useState(true);

  const [article, setArticle] = useState([
    {
      title: "Ethics Committee Approval ",
      src: Efficacy_of_vonoprazan,
      description: `Efficacy of vonoprazan for initial and maintenance
      therapy in reflux esophagitis`,
    },
    {
      title: "Evidance based guideline 2021",
      src: Evidance_based_guideline_2021,
      description: `Evidence-based clinical practice guidelines for gastroesophageal
      reflux disease 2021...`,
    },
    {
      title: "Maintenance of Erosive esophagitis",
      src: Maintenance_of_Erosive_esophagitis,
      description: `Vonoprazan Versus Lansoprazole for Healing and Maintenance
      of Healing...`,
    },
    {
      title: "P CAB GERD",
      src: P_CAB_GERD,
      description: `Potassium-competitive acid blockers and gastroesophageal reflux 
      disease...`,
    },
    {
      title: "Pharmacodynamics and pharmacokinetics",
      src: pharmacodynamics_and_pharmacokinetics,
      description: `Pharmacodynamics and Pharmacokinetics of the
      Potassium-Competitive...`,
    },
    {
      title: "Phase 3 clinical trial vonoprazan",
      src: Phase_3_clinical_trial_vonoprazan,
      description: ` Phase III, randomised, double- blind, multicentre study 
      to evaluate the efficacy...`,
    },
    {
      title: "Randomised trial of acid inhibition by vonoprazan",
      src: Randomised_trial_of_acid_inhibition_by_vonoprazan_10_20_mg_once_daily_vs_Rabeprazole,
      description: `Randomised trial of acid inhibition by vonoprazan 10/20 mg 
      once daily...`,
    },
    {
      title: "Vonoprazan for GERD clinical study",
      src: Vonoprazan_for_GERD_clinical_study,
      description: ` Short‑Term Symptomatic Relief in Gastroesophageal Reflux Disease: 
      A Comparative...`,
    },
    {
      title: "Vonoprazan for GERD",
      src: Vonoprazan_for_GERD,
      description: ` Vonoprazan fumarate, a novel potassium
      competitive acid blocker, in the management 
      ...`,
    },
    {
      title: "Vonoprazan for H pylori",
      src: Vonoprazan_for_H_pylori,
      description: ` Vonoprazan and Helicobacter pylori
      Treatment: A Lesson From Japan...`,
    },
    {
      title: "Vonoprazan for NERD",
      src: Vonoprazan_for_NERD,
      description: `Evaluation of the Efficacy and Safety of Vonoprazan in Patients...`,
    },
    {
      title: "Vonoprazan fumarate for acid related disorders",
      src: Vonoprazan_fumarate_for_acid_related_disorders,
      description: ` Vonoprazan Fumarate for the Management of
      Acid-Related Diseases...`,
    },
    {
      title: "Vonoprazan prevents NSAIDs induced ulcers",
      src: Vonoprazan_prevents_NSAIDs_induced_ulcers,
      description: ` 2020 Seoul Consensus on the Diagnosis and 
      Management of Gastroesophageal...`,
    },
    {
      title: "Vonoprazan prevents recurrent ulcers",
      src: Vonoprazan_prevents_recurrent_ulcers,
      description: `Vonoprazan prevents ulcer recurrence during  
      long-term NSAID therapy: randomised...`,
    },
    {
      title: "Vonoprazan Guideline",
      src: VonoprazanGuideline1,
      description: ` Vonoprazan prevents low-dose aspirin-associated 
      ulcer recurrence: randomised...`,
    },
  ]);

  const [videos, setVideos] = useState([
    {
      video_src:
        "https://www.youtube.com/embed/cVPMlWPlzN4?si=WfgWS8XJDMk9sx-m",
      video_dec:
        "Gastro Podcast Video 1",
      video_title: "Gastro Podcast Video 1",
      sequence: 1,
    },
    {
      video_src:
        "https://www.youtube.com/embed/YuMV5cmYES8?si=wnYbPDDS49W17Xve",
      video_dec:
        "Gastro Podcast Video 2",
      video_title: "Gastro Podcast Video 2",
      sequence: 2,
    },

    {
      video_src:
        "https://www.youtube.com/embed/f6uNtVRHcXc?si=IfrH6aTMDTVFcFRW",
      video_dec: "Gastro Podcast Video 3",
      video_title: "Gastro Podcast Video 3",
      sequence: 3,
    },
    {
      video_src:
        "https://www.youtube.com/embed/qk-G5kAueeM?si=wOG9BbBmpua1zNVL",
      video_dec:
        "Gastro Podcast Video 4",
      video_title: "Gastro Podcast Video 4",
      sequence: 4,
    },
    {
      video_src:
        "https://www.youtube.com/embed/yB3eJdtJu6I?si=xNsYaQ-8_-w1BO41",
      video_dec:
        "Gastro Podcast Video 5",
      video_title: "Gastro Podcast Video 5",
      sequence: 5,
    },
    {
      video_src:
        "https://www.youtube.com/embed/WknfQvjJtq4?si=cVgr7c9fdo3tV8D9",
      video_dec:
        "Gastro Podcast Video 6",
      video_title: "Gastro Podcast Video 6",
      sequence: 6,
    },
    {
      video_src:
        "https://www.youtube.com/embed/vjl2CH4EJ7g?si=sionfoRp_D4Gvglx",
      video_dec:
        "Gastro Podcast Video 7 - 1",
      video_title: "Gastro Podcast Video 7 - 1",
      sequence: 7,
    },
    {
      video_src:
        "https://www.youtube.com/embed/emP-xQLbbFo?si=R55xQewGExpyiBlN",
      video_dec:
        "Gastro Podcast Video 7 - 2",
      video_title: "Gastro Podcast Video 7 - 2",
      sequence: 8,
    },
    {
      video_src:
        "https://www.youtube.com/embed/noiv2J6foog?si=8BAjYj04ZOP-6rQ6",
      video_dec:
        "Gastro Podcast Video 8",
      video_title: "Gastro Podcast Video 8",
      sequence: 9,
    },
    {
      video_src:
        "https://www.youtube.com/embed/X-9d-3NOg9M?si=1xgyb7f1kFhdUfSd",
      video_dec:
        "Gastro Podcast Video 9",
      video_title: "Gastro Podcast Video 9",
      sequence: 10,
    },
    {
      video_src:
        "https://www.youtube.com/embed/h-yvcLmTAsE?si=Jh4UZPmMLzzvSgPV",
      video_dec: "Gastro Podcast Video 10",
      video_title: "Gastro Podcast Video 10",
      sequence: 11,
    },
    {
      video_src:
        "https://www.youtube.com/embed/GYQiw2txXqg?si=gIdLRzKcEu93AhnY",
      video_dec: "Gastro Podcast Video 11",
      video_title: "Gastro Podcast Video 11",
      sequence: 12,
    },
    {
      video_src:
        "https://www.youtube.com/embed/Ug-FYblpBZQ?si=ezWrJQnLRqVsgyQb",
      video_dec: "Gastro Podcast Video 12",
      video_title: "Gastro Podcast Video 12",
      sequence: 13,
    },
    {
      video_src:
        "https://www.youtube.com/embed/E8fQmiKOr6I?si=PL5CdX57SCsarJdo",
      video_dec:
        "Gastro Podcast Video 13",
      video_title: "Gastro Podcast Video 13",
      sequence: 14,
    },
    {
      video_src:
        "https://www.youtube.com/embed/2_tbNqYgsZg?si=2OKMHNvWlxTjbp12",
      video_dec:
        "Gastro Podcast Video 14",
      video_title: "Gastro Podcast Video 14",
      sequence: 15,
    },
    {
      video_src:
        "https://www.youtube.com/embed/xviwNU6s-dg?si=bgOZhTcLAlTNmhqL",
      video_dec: "Gastro Podcast Video 15",
      video_title: "Gastro Podcast Video 15",
      sequence: 16,
    },
   
  ]);

  const toggleDescription = (index) => {
    setExpandedDescriptionIndices((prevIndices) => {
      if (prevIndices.includes(index)) {
        return prevIndices.filter((i) => i !== index); // Collapse description if already expanded
      } else {
        return [...prevIndices, index]; // Expand description
      }
    });
  };

  const openPdf = (pdfSrc) => {
    if (isMobile()) {
      // Open PDF in a new tab/window on mobile devices
      window.open(pdfSrc, "_blank");
    } else {
      setSelectedPdf(pdfSrc);
      setShowPdfModal(true);
    }
  };

  const closePdf = () => {
    setSelectedPdf(null);
    setShowPdfModal(false);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchChangeVideo = (event) => {
    setSearchQueryVideo(event.target.value);
  };

  const clearSearch = () => {
    setSearchQuery("");
  };

  const filteredVideos = videos
    .filter((item) =>
      item.video_dec.toLowerCase().includes(searchQueryVideo.toLowerCase())
    )
    .sort((a, b) => a.sequence - b.sequence);

  const filteredArticles = article
    .filter((item) =>
      item.title.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .sort((a, b) => a.sequence - b.sequence); // Sort articles by sequence number

  const renderPdf = () => {
    if (!selectedPdf) return null;
    return (
      <Modal show={showPdfModal} onHide={closePdf} size="lg" centered>
        <Modal.Body>
          <iframe
            title="pdfViewer"
            src={selectedPdf}
            style={{ width: "100%", height: "80vh", border: "none" }}
          />
        </Modal.Body>
      </Modal>
    );
  };

  const isMobile = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return userAgent.includes("mobile") || userAgent.includes("android");
  };

  const handleArticleTabClicked = () => {
    setOpenArticleTab(true);
    setOpenVideoTab(false);
  };

  const handleVideoTabClicked = () => {
    setOpenVideoTab(true);
    setOpenArticleTab(false);
  };

  const handleBackBtn = () => {
    setOpenArticleTab(false);
    setOpenVideoTab(false);
    setPlayingVideoIndex(null); // Stop playing any video when back button is clicked
  };

  const handleVideoPlay = (index) => {
    setPlayingVideoIndex(index);
  };

  const handleCloseVideo = () => {
    setPlayingVideoIndex(null);
  };

  const readArticleText = "Read Full Article >";
  const playVideoText = "Play Video >";

  return (
    <>
      <div className="video-article-tab-container">
        <div style={{ display: "flex" }}>
          {/* <div
            className={openArticleTab ? "selected-tab" : "article-tab"}
            onClick={handleArticleTabClicked}
          >
            <h4> Articles </h4>
            <img src={article_image} alt="Article Tab" />
          </div> */}
          <div
            className={openVideoTab ? "selected-tab" : "video-tab"}
            onClick={handleVideoTabClicked}
          >
            <h4> Videos</h4>
            <img src={yt_img} alt="Video Tab" />
          </div>
        </div>

        <div className={openArticleTab ? "search-container" : "none"}>
          <input
            type="text"
            placeholder="Search by title..."
            value={searchQuery}
            onChange={handleSearchChange}
            id="search-box"
          />
          {searchQuery && (
            <button className="clear-btn" onClick={clearSearch}>
              &#x2715;
            </button>
          )}
        </div>

        <div className={openVideoTab ? "search-container-video" : "none"}>
          <input
            type="text"
            placeholder="Search by question..."
            value={searchQueryVideo}
            onChange={handleSearchChangeVideo}
            id="search-box-video"
          />
          {searchQueryVideo && (
            <button
              className="clear-btn"
              id="clear-btn-video"
              onClick={clearSearch}
            >
              &#x2715;
            </button>
          )}
        </div>
      </div>

      {/* search-container */}

      <Row className="align-items-center">
        <Col lg={5} md={6}>
          <div className="row align-items-center">
            {/* <div className={openArticleTab ? 'search-container' : "none"}>
              <input
                type="text"
                placeholder="Search by title..."
                value={searchQuery}
                onChange={handleSearchChange}
                id="search-box"
              />
              {searchQuery && (
                <button className="clear-btn" onClick={clearSearch}>
                  &#x2715;
                </button>
              )}
            </div> */}

            {/* <div className={openVideoTab ? 'search-container-video' : "none"}>
              <input
                type="text"
                placeholder="Search by question..."
                value={searchQueryVideo}
                onChange={handleSearchChangeVideo}
                id="search-box-video"
              />
              {searchQueryVideo && (
                <button className="clear-btn" onClick={clearSearch}>
                  &#x2715;
                </button>
              )}
            </div> */}
          </div>
        </Col>
      </Row>

      <Row>
        <span>{/* <strong>Coming Soon.....</strong> */}</span>

        {/* className="video-article-tab-container" */}
        {/* <div className={openArticleTab || openVideoTab ? 'back-btn-container' : 'none'}>
          <button className="back-btn" onClick={handleBackBtn}> Back </button>
        </div> */}

        {/* article-tab */}

        {/* className="cards-container" */}

        <div className={openArticleTab ? "cards-container" : "none"}>
          {filteredArticles.map((item, index) => (
            <div className="card" id="card" key={index}>
              <div>
                <img
                  src={pdf_img}
                  height="50px"
                  style={{ cursor: "pointer" }}
                  alt="PDF"
                />
                <strong>{item.title}</strong>
              </div>
              <span onClick={() => openPdf(item.src)}> {readArticleText}</span>
            </div>
          ))}
        </div>

        <div className={openVideoTab ? "video-cards-container" : "none"}>
          {filteredVideos.map((item, index) => (
            <div className="video-card" id="video-card" key={index}>
              <div>
                {/* <strong>{item.video_title}</strong> */}

                <strong>
                  {/* {expandedDescriptionIndices.includes(index)
                    ? item.video_dec
                    : `${item.video_dec.slice(0, 30)}...`}
                  {!expandedDescriptionIndices.includes(index) && (
                    <button onClick={() => toggleDescription(index)} id="more-btn">
                      More
                    </button>
                  )} */}

                  <img src={yt_video_icon} height="50px" alt="Play Video" />

                  {item.video_dec}
                </strong>
              </div>
              <span onClick={() => handleVideoPlay(index)}>
                {" "}
                {playVideoText}{" "}
              </span>
              {playingVideoIndex === index && (
                <>
                  <div className="video-player-container">
                    <div className="close-btn">
                      <button
                        className="video-close-btn"
                        id="video-close-btn"
                        onClick={handleCloseVideo}
                      >
                        &#x2715;
                      </button>
                    </div>
                    <iframe
                      width="400"
                      height="315"
                      src={item.video_src}
                      title={item.video_title}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      // allowFullScreen
                    ></iframe>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </Row>

      {renderPdf()}
    </>
  );
}

export default DisplayVideo;
